
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    return _createElement('div', { 'className': 'cm_browse-all' }, _createElement('a', {
        'href': this.makeHref({ useRelated: true }),
        'target': '_blank'
    }, 'More Like This (Adjustable)'));
}
        export const componentNames = []