import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { RelatedItems } from 'Components'
import TemplateproductrelatedItems from 'Stores/tradingpostfansandlighting/Templates/product/relatedItems.rt'
import { ContextDialog } from 'Components'
import TemplateDialog from 'Stores/_default-store/Templates/Dialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { SearchHeader } from 'Components'
import { FilterSearchBox } from 'Components'
import { FacetPanel } from 'Components'
import { SearchResult } from 'Components'

export const compMap = {
  SearchPage,
SearchBoxDialogButton,
RelatedItems,
ContextDialog,
SearchBoxDialog,
SearchHeader,
FilterSearchBox,
FacetPanel,
SearchResult
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'product/relatedItems': TemplateproductrelatedItems,
'Dialog': TemplateDialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};